import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MainGuard implements CanActivate, CanActivateChild {
  constructor(public router: Router) {
  }

  canActivate() {
    const authValidation = sessionStorage.getItem('AuthValidation');

    if (authValidation) {
      return true;
    }

    this.router.navigate(['login']);

    return false;
  }

  canActivateChild() {
    const authValidation = sessionStorage.getItem('AuthValidation');

    if (authValidation) {
      return true;
    }

    this.router.navigate(['login']);

    return false;
  }
}
