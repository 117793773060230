import {FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  constructor(private httpClient: HttpClient) {
  }

  // ================================================================================
  // Events
  // ================================================================================

  public generateRandomNumber(max: number = 10, min: number = 0): number {
    return Math.floor(Math.random() * (max - min) + min);
  }

  public generateRandomAlphaNumericString(): string {
    return (Math.random() + 1).toString(36).substring(2);
  }

  public formatDate(value: any): string {
    return value ? moment(value).format('DD/MM/YYYY') : '-';
  }

  public getList(model: string) {
    return new Promise((resolve) => {
      this.httpClient.get(`${model}?order=name asc`).subscribe((result) => {
        resolve(result);
      });
    });
  }

  public insertObject(model: string, object: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.post(`${model}`, object).subscribe((result) => {
        resolve(result);
      });
    });
  }

  public isFormFieldValid(form: FormGroup, formField: string): boolean {
    return form.get(formField).touched && form.get(formField).invalid;
  }

  public setErrorMessage(form: FormGroup, formField: string, type?: string): string {
    switch (type) {
      case 'article':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.min) {
          return 'Πρέπει να είναι μεγαλύτερο από 1.';
        }

        break;
      case 'email':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.email) {
          return 'Λάθος τύπος email.';
        }

        break;
      case 'postalCode':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.minlength) {
          return 'Συμπληρώστε 5 ψηφία.';
        }

        break;
      case 'telephone':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.minlength) {
          return 'Συμπληρώστε 10 ψηφία.';
        }

        break;
      case 'temporaryPassword':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.minlength) {
          return 'Συμπληρώστε 6 ψηφία.';
        }

        break;
      case 'vatNumber':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.minlength) {
          return 'Συμπληρώστε 9 ψηφία.';
        }

        if (form.get(formField).errors.invalidVatNumber) {
          return 'Λάθος μορφή Α.Φ.Μ..';
        }

        if (form.get(formField).errors.uniqueVatNumber) {
          return 'Υπάρχει εταιρεία με το ίδιο Α.Φ.Μ..';
        }

        break;
      default:
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        break;
    }
  }

  public setValue(object: any): string {
    return object ? object : '-';
  }

  public showTable(table: any[]): boolean {
    return table.length > 0;
  }
}
