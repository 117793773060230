const apiRoutes = {
  auth: {
    login: 'login/mobile'
  },
  user: {
    self: 'user/me'
  },
  salePoints: 'sale_point',
  refrigerators: 'refrigerator',
  refrigeratorOrders: 'request/mobile',
  refrigeratorMaterials: 'material',
  refrigeratorReturnRequests: 'return_command/mobile',
  inventories: 'inventory',
  loadRequests: 'loading_command/mobile',
  unloadRequests: 'unloading_command/mobile'
};

export default apiRoutes;
