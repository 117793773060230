export interface Route {
  path: string;
  title: string;
  icon: string;
}

const pages = {
  home: {
    path: '/home',
    title: 'Αρχική',
    icon: 'home'
  },
  salePoints: {
    path: '/sale-points',
    title: 'Σημεία Πώλησης',
    icon: 'store'
  },
  inventories: {
    path: '/inventories',
    title: 'Απογραφές',
    icon: 'boxes'
  },
  loadRequests: {
    path: '/load-requests/pending',
    title: 'Εντολές Φόρτωσης',
    icon: 'truck-loading'
  },
  unloadRequests: {
    path: '/unload-requests/pending',
    title: 'Εντολές Παραλαβής',
    icon: 'truck-loading'
  },
  refrigeratorOrders: {
    path: '/refrigerator-orders',
    title: 'Αιτήσεις ψυγείων',
    icon: 'shopping-cart'
  },
  refrigeratorReturnRequests: {
    path: '/refrigerator-return-requests',
    title: 'Εντολές επιστροφής ψυγείων',
    icon: 'truck-loading'
  }
};

// TODO: Change data download manager based on role changes

export const driver: Route[] = [pages.home, pages.salePoints, pages.inventories];

export const regionalDirector: Route[] = [pages.home, pages.salePoints, pages.refrigeratorOrders];

export const ownNetworkRepresentative: Route[] = [
  pages.home, pages.salePoints, pages.inventories, pages.unloadRequests, pages.refrigeratorReturnRequests];

export const ownNetworkDistributor: Route[] = [pages.home, pages.salePoints, pages.inventories];

export const districtRepresentative: Route[] =
  [pages.home, pages.salePoints, pages.inventories, pages.unloadRequests, pages.refrigeratorReturnRequests];

export const salesInspector: Route[] =
  [pages.home, pages.salePoints, pages.inventories, pages.refrigeratorOrders, pages.refrigeratorReturnRequests];

export const technician: Route[] = [pages.home, pages.salePoints, pages.inventories];

export const manufacturingCompanyRepresentative: Route[] = [pages.home, pages.loadRequests];

export const warehouseManager: Route[] =
  [pages.home, pages.salePoints, pages.inventories, pages.loadRequests, pages.unloadRequests, pages.refrigeratorReturnRequests];

export const manager3PL: Route[] = [pages.home, pages.loadRequests, pages.unloadRequests];

export const receiptPointOfficer: Route[] = [pages.home, pages.unloadRequests];
