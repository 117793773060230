import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import UnloadRequest, {IToUnload, ILoadedRefrigerator} from '../../models/load-unload/UnloadRequest.model';
import {groupBy as _groupBy, find as _find, cloneDeep as _cloneDeep} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import cacheKeys from '../../configs/cache-keys.config';
import {NetworkService} from '../shared/offline/network.service';
import {CachingService} from '../shared/offline/caching.service';
import {OfflineManagerService} from '../shared/offline/offline-manager.service';
import {CommonService} from '../shared/common/common.service';
import apiRoutes from '../../configs/api-routes.config';

@Injectable({
  providedIn: 'root'
})
export class UnloadRequestsService {

  private CACHE_KEY = cacheKeys.unloadRequests;

  private unloadRequests: UnloadRequest[] = [];

  private pendingUnloadRequests: UnloadRequest[] = [];
  private pendingUnloadRequestsSubject: BehaviorSubject<UnloadRequest[]>;

  private unloadedUnloadRequests: UnloadRequest[] = [];
  private unloadedUnloadRequestsSubject: BehaviorSubject<UnloadRequest[]>;

  constructor(private httpClient: HttpClient,
              private commonService: CommonService,
              private network: NetworkService,
              private cachingService: CachingService,
              private offlineManagerService: OfflineManagerService) {
    this.initService();
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Fetches unload requests from the server
   */
  public async fetchUnloadRequests(): Promise<UnloadRequest[]> {
    try {
      if (this.network.isOnline()) {
        this.unloadRequests = await this.httpClient.get<UnloadRequest[]>(apiRoutes.unloadRequests).toPromise();
        await this.cachingService.setLocalData(this.CACHE_KEY, this.unloadRequests);
      } else {
        const result: UnloadRequest[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.unloadRequests = result ? result : [];
      }
      this.groupAndUpdateUnloadRequests();
      return Promise.resolve(this.getUnloadRequests());
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Fetches an unload request by id
   */
  public async fetchUnloadRequestById(id: number): Promise<UnloadRequest> {
    try {
      let unloadRequest: UnloadRequest = null;
      if (this.network.isOnline()) {
        unloadRequest = await this.httpClient.get<UnloadRequest>(`${apiRoutes.unloadRequests}/${id}`).toPromise();
        this.replaceUnloadRequest(unloadRequest);
        this.groupAndUpdateUnloadRequests();
      } else {
        unloadRequest = await this.cachingService.getLocalDataById(this.CACHE_KEY, id);
      }
      return Promise.resolve(unloadRequest);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * PUT an unload request
   */
  public async updateUnloadRequest(unloadRequest: UnloadRequest): Promise<UnloadRequest> {
    try {
      const serverUnloadRequest = await this.fetchUnloadRequestById(unloadRequest.id);
      unloadRequest = this.filterOnlyUpdatedRefrigerators(unloadRequest, serverUnloadRequest);
      let updatedUnloadRequest: UnloadRequest = null;
      if (this.network.isOnline()) {
        updatedUnloadRequest =
          await this.httpClient
            .put<UnloadRequest>(`${apiRoutes.unloadRequests}/${unloadRequest.id}?stage_id=4`, unloadRequest).toPromise();
      } else {
        await this.offlineManagerService
          .storeRequest(`${apiRoutes.unloadRequests}/${unloadRequest.id}?stage_id=4`, 'PUT', unloadRequest);
        updatedUnloadRequest = unloadRequest;
      }
      this.replaceUnloadRequest(updatedUnloadRequest);
      this.groupAndUpdateUnloadRequests();
      return Promise.resolve(updatedUnloadRequest);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  /**
   * Returns unload requests
   */
  public getUnloadRequests(): UnloadRequest[] {
    return _cloneDeep(this.unloadRequests);
  }

  /**
   * Returns pending unload requests as an observable
   */
  public getPendingUnloadRequests(): Observable<UnloadRequest[]> {
    return this.pendingUnloadRequestsSubject.asObservable();
  }

  /**
   * Returns unloaded unload requests as an observable
   */
  public getUnloadedUnloadRequests(): Observable<UnloadRequest[]> {
    return this.unloadedUnloadRequestsSubject.asObservable();
  }

  public resetState(): void {
    this.unloadRequests = [];
    this.groupAndUpdateUnloadRequests();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private async replaceUnloadRequest(unloadRequest: UnloadRequest): Promise<void> {
    if (unloadRequest) {
      this.unloadRequests = this.unloadRequests.map(entry => {
        return entry.id === unloadRequest.id ? unloadRequest : entry;
      });
      await this.cachingService.replaceLocalData(this.CACHE_KEY, unloadRequest);
    }
  }

  private groupAndUpdateUnloadRequests(): void {
    this.groupUnloadRequestsByStatusId(this.unloadRequests);
    this.updateUnloadRequestsSubject('all');
  }

  private updateUnloadRequestsSubject(type: string): void {
    switch (type) {
      case 'pending':
        this.pendingUnloadRequestsSubject.next(this.pendingUnloadRequests);
        break;
      case 'unloaded':
        this.unloadedUnloadRequestsSubject.next(this.unloadedUnloadRequests);
        break;
      case 'all':
        this.pendingUnloadRequestsSubject.next(this.pendingUnloadRequests);
        this.unloadedUnloadRequestsSubject.next(this.unloadedUnloadRequests);
    }
  }

  /**
   * Groups unload requests based on their status id
   * Status ID 4: Belongs to pending requests
   * Status ID 5: Belongs to unloaded requests
   */
  private groupUnloadRequestsByStatusId(unloadRequests: UnloadRequest[]): void {
    const groupByStatusId = _groupBy(unloadRequests, 'status_id');
    this.pendingUnloadRequests = [];
    if (groupByStatusId['1']) { this.pendingUnloadRequests.push(...groupByStatusId['1']); }
    if (groupByStatusId['2']) { this.pendingUnloadRequests.push(...groupByStatusId['2']); }
    this.unloadedUnloadRequests = [];
    if (groupByStatusId['3']) { this.unloadedUnloadRequests.push(...groupByStatusId['3']); }
    if (groupByStatusId['4']) { this.unloadedUnloadRequests.push(...groupByStatusId['4']); }
  }

  /**
   * Filters the refrigerators of the unload request, keeping only the ones that are updated
   * Stage ID 4: Keeps all refrigerators which are now unloaded
   */
  private filterOnlyUpdatedRefrigerators(
    localUnloadRequest: UnloadRequest, serverUnloadRequest: UnloadRequest): UnloadRequest {
    const filteredUnloadRequest: UnloadRequest = _cloneDeep(localUnloadRequest);

    filteredUnloadRequest.details.forEach((entry: IToUnload, index) => {
      entry.serial_numbers = entry.serial_numbers.filter((refrigerator: ILoadedRefrigerator) => {
        return !_find(serverUnloadRequest.details[index].serial_numbers,
          { stage_id: 4, serial_number: refrigerator.serial_number, date_executed: refrigerator.date_executed });
      });
    });

    return filteredUnloadRequest;
  }

  private initService() {
    this.pendingUnloadRequestsSubject = new BehaviorSubject<UnloadRequest[]>([]);
    this.unloadedUnloadRequestsSubject = new BehaviorSubject<UnloadRequest[]>([]);
  }
}
