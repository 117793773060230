import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import cacheKeys from '../../configs/cache-keys.config';
import RefrigeratorOrder, {RefrigeratorOrderDetail} from '../../models/refrigerator/order/RefrigeratorOrder.model';
import {HttpClient} from '@angular/common/http';
import {NetworkService} from '../shared/offline/network.service';
import {CachingService} from '../shared/offline/caching.service';
import {OfflineManagerService} from '../shared/offline/offline-manager.service';
import { cloneDeep as _cloneDeep, find as _find, reject as _reject } from 'lodash';
import {LoadRequest} from '../../models/load-unload/LoadRequest.model';
import apiRoutes from '../../configs/api-routes.config';
import * as date from '../../helpers/date/date.helper';

@Injectable({
  providedIn: 'root'
})
export class RefrigeratorOrdersService {

  private CACHE_KEY = cacheKeys.refrigeratorOrders;

  private refrigeratorOrders: RefrigeratorOrder[] = [];
  private refrigeratorOrdersSubject: BehaviorSubject<RefrigeratorOrder[]>;

  constructor(private httpClient: HttpClient,
              private network: NetworkService,
              private cachingService: CachingService,
              private offlineManagerService: OfflineManagerService) {
    this.initService();
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Fetches refrigerator orders from the server
   */
  public async fetchOrders(): Promise<RefrigeratorOrder[]> {
    try {
      if (this.network.isOnline()) {
         this.refrigeratorOrders = await this.httpClient.get<RefrigeratorOrder[]>(apiRoutes.refrigeratorOrders).toPromise();
         await this.cachingService.setLocalData(this.CACHE_KEY, this.refrigeratorOrders);
      } else {
        const result: RefrigeratorOrder[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.refrigeratorOrders = result ? result : [];
      }
      this.updateRefrigeratorOrdersSubject();
      return Promise.resolve(this.getOrders());
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Fetches a refrigerator order by id
   */
  public async fetchOrderById(id: number): Promise<RefrigeratorOrder> {
    try {
      let order: RefrigeratorOrder = null;
      if (this.network.isOnline()) {
        order = await this.httpClient.get<RefrigeratorOrder>(`${apiRoutes.refrigeratorOrders}/${id}`).toPromise();
        this.replaceRefrigeratorOrder(order);
        this.updateRefrigeratorOrdersSubject();
      } else {
        order = await this.cachingService.getLocalDataById(this.CACHE_KEY, id);
      }
      return Promise.resolve(order);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * POST a refrigerator order
   */
  public async createOrder(order: RefrigeratorOrder): Promise<RefrigeratorOrder> {
    try {
      this.prepareOrderToBeCreated(order);
      let createdOrder: RefrigeratorOrder = null;
      if (this.network.isOnline()) {
        createdOrder = await this.httpClient.post<RefrigeratorOrder>(apiRoutes.refrigeratorOrders, order).toPromise();
      } else {
        await this.offlineManagerService.storeRequest(apiRoutes.refrigeratorOrders, 'POST', order);
        createdOrder = order;
      }
      this.addRefrigeratorOrder(createdOrder);
      this.updateRefrigeratorOrdersSubject();
      return Promise.resolve(createdOrder);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  /**
   * PUT a refrigerator order
   */
  public async updateOrder(order: RefrigeratorOrder, pushResult: boolean = true): Promise<RefrigeratorOrder> {
    try {
      this.prepareOrderToBeUpdated(order);
      let updatedOrder: RefrigeratorOrder = null;
      const url = `${apiRoutes.refrigeratorOrders}/${order.id}`;
      if (this.network.isOnline()) {
        updatedOrder = await this.httpClient.put<RefrigeratorOrder>(url, order).toPromise();
      } else {
        await this.offlineManagerService.storeRequest(url, 'PUT', order);
        updatedOrder = order;
      }
      if (pushResult) {
        this.replaceRefrigeratorOrder(updatedOrder);
      } else {
        this.removeRefrigeratorOrder(order.id);
      }
      this.updateRefrigeratorOrdersSubject();
      return Promise.resolve(updatedOrder);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  /**
   * Returns refrigerator orders
   */
  public getOrders(): RefrigeratorOrder[] {
    return _cloneDeep(this.refrigeratorOrders);
  }

  /**
   * Returns refrigerator order by id
   */
  public getOrderById(id: number): RefrigeratorOrder {
    return _cloneDeep(_find(this.refrigeratorOrders, ['id', id]));
  }

  /**
   * Returns refrigerator orders as an observable
   */
  public observeOrders(): Observable<RefrigeratorOrder[]> {
    return this.refrigeratorOrdersSubject.asObservable();
  }

  public resetState(): void {
    this.refrigeratorOrders = [];
    this.updateRefrigeratorOrdersSubject();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private async addRefrigeratorOrder(order: RefrigeratorOrder): Promise<void> {
    if (order) {
      this.refrigeratorOrders.push(order);
      await this.cachingService.pushLocalData(this.CACHE_KEY, order);
    }
  }

  private async removeRefrigeratorOrder(id: number): Promise<void> {
    if (id > -1) {
      this.refrigeratorOrders = _reject(this.refrigeratorOrders, ['id', id]);
      await this.cachingService.removeLocalData(this.CACHE_KEY, 'id', id);
    }
  }

  private async replaceRefrigeratorOrder(order: RefrigeratorOrder): Promise<void> {
    if (order) {
      this.refrigeratorOrders = this.refrigeratorOrders.map(entry => {
        return entry.id === order.id ? order : entry;
      });
      await this.cachingService.replaceLocalData(this.CACHE_KEY, order);
    }
  }

  private prepareOrderToBeCreated(order: RefrigeratorOrder): void {
    order.desired_delivery_date = date.formatDateStringToISOString(order.desired_delivery_date);
    order.date_created = date.currentISOStringDate();
  }

  private prepareOrderToBeUpdated(order: RefrigeratorOrder): void {
    order.details.forEach((entry: RefrigeratorOrderDetail) => {
      if (entry.has_new_refrigerators) {
        // Populate approved value
        if (!entry.approved) { entry.approved = _cloneDeep(entry.original); }
        // Limit approved new refrigerators based on quantity budget and recalculate
        if (entry.approved.new > entry.quantity_budget) {
          entry.approved.new = entry.quantity_budget;
          entry.approved.used = entry.quantity - entry.approved.new;
        }
        // Re evaluate the flag
        entry.has_new_refrigerators = entry.approved.new > 0;
      }
    });
  }

  private updateRefrigeratorOrdersSubject(): void {
    this.refrigeratorOrdersSubject.next(this.getOrders());
  }

  private initService(): void {
    this.refrigeratorOrdersSubject = new BehaviorSubject<RefrigeratorOrder[]>([]);
  }
}
