import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) {
  }

  public present(message: string) {
    this.toastController.create({
      message,
      duration: 2000
    }).then(toast => toast.present());
  }
}
