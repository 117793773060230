const messages = {
  dialog: {
    yes: 'Ναι',
    no: 'Όχι',
    ok: 'Οκ',
    error: 'Σφάλμα',
    attention: 'Προσοχή'
  },
  common: {
    success: {
      saved: 'Αποθηκεύτηκε',
      deleted: 'Διαγράφηκε',
      sent: 'Στάλθηκε'
    },
    error: 'Κάτι πήγε στραβά. Προσπαθήστε ξανά'
  },
  caching: {
    dataDownload: 'Λήψη δεδομένων',
    success: 'Τα δεδομένα αποθηκεύτηκαν',
    error: {
      offline: 'Η λήψη δεδομένων δεν πραγματοποιείται εκτός σύνδεσης'
    }
  },
  offlineManager: {
    loading: 'Αποστολή αποθηκευμένων αιτημάτων',
    success: 'Τα αιτήματα στάλθηκαν',
    error: 'Κάποια αιτήματα δεν στάλθηκαν'
  },
  camera: {
    success: {
      saved: 'Η φωτογραφία αποθηκεύτηκε',
      added: 'Η φωτογραφία προστέθηκε'
    },
    error: 'Δεν είναι δυνατή η λήψη φωτογραφιών.'
  },
  scan: {
    barcode: {
      error: {
        scanFailed: 'Δεν ήταν δυνατό το σκανάρισμα του σειριακού αριθμού',
        scanNotSupported: 'Το σκανάρισμα δεν υποστηρίζεται από την συσκευή'
      }
    },
    nfc: {
      action: {
        enableNFC: 'Ενεργοποίηση NFC',
        useBarcodeScanner: 'Συνέχεια με Barcode'
      },
      warning: {
        notEnabled: 'Η λειτουργία NFC είναι απενεργοποιημένη',
      },
      error: {
        scanFailed: 'Η λήψη του σειριακού αριθμού απέτυχε',
        notSupported: 'Το NFC δεν υποστηρίζεται από την συσκευή'
      }
    }
  },
  geolocation: {
    error: 'Δεν βρέθηκε γεωγραφική θέση'
  },
  salePoints: {
    success: {
      created: 'Το σημείο πώλησης δημιουργήθηκε',
      deleted: 'Το σημείο πώλησης διαγράφηκε',
      updated: 'Το σημείο πώλησης ενημερώθηκε'
    },
    error: {
      retrieval: 'Αποτυχία λήψης σημείων πώλησης'
    }
  },
  inventories: {
    success: {
      created: 'Η απογραφή δημιουργήθηκε',
      deleted: 'Η απογραφή διαγράφηκε',
      updated: 'Η απογραφή ενημερώθηκε'
    },
    error: {
      retrieval: 'Αποτυχία λήψης απογραφών'
    }
  },
  loadRequests: {
    success: {
      sent: 'Η εντολή στάλθηκε',
      saved: 'Η εντολή αποθηκεύτηκε',
      markedAsToSend: 'Η εντολή ορίστηκε προς αποστολή',
      refrigeratorLoaded: 'Το ψυγείο φορτώθηκε'
    },
    error: {
      noLoadedRefrigerators: 'Δεν έχει φορτωθεί κανένα ψυγείο',
      retrieval: 'Αποτυχία λήψης εντολών φόρτωσης',
      singleRetrieval: 'Αποτυχία λήψης εντολής φόρτωσης',
      refrigeratorDoesNotBelongHere: 'Το ψυγείο αυτό δεν ανήκει στην εντολή φόρτωσης',
      refrigeratorAlreadyLoaded: 'Το ψυγείο αυτό έχει φορτωθεί ήδη',
      loadedListIsFull: 'Η λίστα φόρτωσης είναι πλήρης'
    }
  },
  unloadRequests: {
    success: {
      saved: 'Η εντολή αποθηκεύτηκε',
      refrigeratorUnloaded: 'Το ψυγείο παραλήφθηκε'
    },
    error: {
      retrieval: 'Αποτυχία λήψης εντολών παραλαβής',
      singleRetrieval: 'Αποτυχία λήψης εντολής παραλαβής',
      alreadyUnloaded: 'Το ψυγείο έχει ήδη παραληφθεί',
      refrigeratorDoesNotBelongHere: 'Το ψυγείο αυτό δεν ανήκει στην εντολή παραλαβής',
      unloadedListIsFull: 'Η λίστα παραλαβής είναι πλήρης'
    }
  },
  refrigeratorOrders: {
    dialog: {
      creation: 'Δημιουργία αίτησης;',
      approve: 'Έγκριση αίτησης;'
    },
    success: {
      created: 'Η αίτηση δημιουργήθηκε',
      deleted: 'Η αίτηση διαγράφηκε',
      updated: 'Η αίτηση ενημερώθηκε',
      approved: 'Η αίτηση εγκρίθηκε'
    },
    error: {
      retrieval: 'Αποτυχία λήψης αιτήσεων',
      singleRetrieval: 'Αποτυχία λήψης αίτησης'
    }
  },
  refrigeratorReturnRequests: {
    dialog: {
      creation: 'Δημιουργία εντολής;',
      missingEntryMaterialDescription: 'Υπάρχουν ψυγεία αγνώστου ή ανευ σειριακού χωρίς περιγραφή',
      withdrawConfirmation: 'Ορισμός ψυγείου ως «προς απόσυρση»;',
      cancelConfirmation: 'Ορισμός ψυγείου ως «άκυρο»;',
      unloadConfirmation: 'Παραλαβή ψυγείου;'
    },
    success: {
      created: 'Η εντολή δημιουργήθηκε',
      deleted: 'Η εντολή διαγράφηκε',
      updated: 'Η εντολή ενημερώθηκε',
      saved: 'Η εντολή αποθηκεύτηκε',
      refrigeratorAdded: 'Το ψυγείο προστέθηκε στην λίστα',
      refrigeratorUnloaded: 'Το ψυγείο παραλήφθηκε',
      refrigeratorWithdrawn: 'Το ψυγείο αποσύρθηκε',
      refrigeratorCanceled: 'Το ψυγείο ακυρώθηκε'
    },
    error: {
      retrieval: 'Αποτυχία λήψης εντολών',
      singleRetrieval: 'Αποτυχία λήψης εντολής',
      refrigeratorAlreadyUnloaded: 'Το ψυγείο έχει ήδη παραληφθεί',
      refrigeratorNotConfirmed: 'Το ψυγείο δεν έχει εγκριθεί',
      refrigeratorExistInList: 'Το ψυγείο υπάρχει ήδη στην εντολή επιστροφής',
      refrigeratorDoesNotBelongHere: 'Το ψυγείο αυτό δεν ανήκει στην εντολή επιστροφής',
    }
  }
};

export default messages;
