export interface Column {
  isMain: boolean;
  name: string;
  title: string;
  type: Types;
}

export class TableConfig {
  public columns: Column[];
  public filter: string;
  public hasStatus: boolean;
  public icon: string;
  public model: string;
  public navigatePath: string;
  public navigateProperty: string;
  public nested: boolean;
}

export enum Types {
  date = 'date',
  text = 'text'
}
