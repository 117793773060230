import {Component, Input, OnInit} from '@angular/core';
import {TableConfig} from '../table-config';

import InventoryRefrigeratorsConfig from '../configs/inventory-refrigerators.json';

@Component({
    templateUrl: './inventory-refrigerators.component.html',
    styleUrls: ['./inventory-refrigerators.component.scss'],
})

export class InventoryRefrigeratorsComponent implements OnInit {
    @Input() filter: string;

    public inventoryRefrigeratorsConfig: TableConfig;

    constructor() {
        this.inventoryRefrigeratorsConfig = InventoryRefrigeratorsConfig;
    }

    ngOnInit(): void {
        this.inventoryRefrigeratorsConfig.filter = this.filter;
    }
}
