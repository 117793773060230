import {ActivatedRoute, Router} from '@angular/router';
import {Column, TableConfig, Types} from './table-config';
import {CommonService} from '../../../shared/services/shared/common/common.service';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InventoryRefrigeratorsComponent} from './inventory-refrigerators/inventory-refrigerators.component';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';
import {RefrigeratorModalComponent} from '../../refrigerators/refrigerator-modal/refrigerator-modal.component';
import {HeaderController} from '../../../shared/services/shared/navigation/header-controller.service';
import {NavigationService} from '../../../shared/services/shared/navigation/navigation.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent implements OnInit {
  @Input() inputConfig: TableConfig;
  @ViewChild(IonInfiniteScroll, {static: false}) infiniteScroll: IonInfiniteScroll;

  public config: TableConfig;
  public data: any[];
  public dataLength: number;
  public initializePage: boolean;
  public pageSize: number;
  private currentPage: number;
  private initialQuery: string;

  constructor(public commonService: CommonService,
              private headerController: HeaderController,
              private httpClient: HttpClient,
              private navigation: NavigationService,
              public modalController: ModalController,
              private route: ActivatedRoute,
              private router: Router) {
    this.currentPage = 0;
    this.data = [];
    this.pageSize = 100;
  }

  // ================================================================================
  // Lifecycle
  // ================================================================================

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data.config) {
        this.config = data.config;
      } else {
        this.config = this.inputConfig;
      }
    });

    if (this.config.filter) {
      this.initialQuery = `${this.config.model}?count=true&limit=${this.pageSize}&filter=${this.config.filter}&skip=`;
    } else {
      this.initialQuery = `${this.config.model}?count=true&limit=${this.pageSize}&skip=`;
    }

    this.httpClient.get(`${this.initializeQuery()}`).subscribe((data: any) => {
      this.data = data.value;

      this.dataLength = data.total;
      this.initializePage = true;
    });
  }

  ionViewDidEnter() {
    let title = '';
    const path = this.navigation.getPath();
    switch (path) {
      case '/sale-points':
        title = 'Σημεία πώλησης';
        break;
      case '/inventories':
        title = 'Απογραφές';
        break;
    }
    this.headerController.setHeaderOptions({showLogo: false, title});
  }

  // ================================================================================
  // Events
  // ================================================================================

  public loadData(event): void {

    this.currentPage++;

    this.httpClient.get(`${this.initializeQuery()}`).subscribe((data: any) => {
      this.data = this.data.concat(data.value);

      event.target.complete();

      if (this.data.length === this.dataLength) {
        event.target.disabled = true;
      }
    });
  }

  public openElementDetails(element: any): void {
    if (this.config.navigatePath) {
      this.router.navigate([this.config.navigatePath, element[this.config.navigateProperty]]);
    } else {
      if (this.config.model === 'inventory_sale_point') {
        if (element.is_completed_value === 1) {
          this.openInventoryRefrigeratorsModal(element);
        } else {
          this.router.navigate(['sale-points', element['sale_point_id']]);
        }
      } else {
        if (this.config.model === 'refrigerator') {
          this.openRefrigeratorModal(element);
        } else if (element.refrigerator_id !== null) {
          this.openRefrigeratorModal(element);
        }
      }
    }
  }

  async openInventoryRefrigeratorsModal(inventory: any) {
    const modal = await this.modalController.create({
      component: InventoryRefrigeratorsComponent,
      componentProps: {
        filter: `inventory_sale_point_id = ${inventory.id}`
      },
      cssClass: 'create-sale-point'
    });

    return await modal.present();
  }

  async openRefrigeratorModal(refrigerator: any) {
    const modal = await this.modalController.create({
      component: RefrigeratorModalComponent,
      componentProps: {
        model: this.config.model,
        refrigerator
      },
      cssClass: 'create-sale-point'
    });

    return await modal.present();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  public initializeQuery(): string {
    return this.initialQuery + (this.pageSize * this.currentPage);
  }

  public formatValue(column: Column, element: any): string {
    switch (column.type) {
      case Types.date:
        return this.commonService.formatDate(element[column.name]);
      case Types.text:
        return element[column.name];
    }
  }

  public isConfigModel(model: string): boolean {
    return this.config.model === model;
  }

  public setColor(inventory: any): string {
    let color;
    if (inventory.date_completed) {
      color = '#4caf50';
    } else if (inventory.inventory_date_completed) {
      color = '#4caf50';
    } else if (inventory.is_completed_value) {
      color = '#4caf50';
    } else {
      color = '#dd0330';
    }
    return color;
  }
}
