import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {ISalePoint as SalePoint} from '../../models/sale-point/SalePoint.model';
import apiRoutes from '../../configs/api-routes.config';
import {BehaviorSubject, Observable} from 'rxjs';
import {isEmpty as _isEmpty} from 'lodash';
import {NetworkService} from '../shared/offline/network.service';
import {CachingService} from '../shared/offline/caching.service';
import { cloneDeep as _cloneDeep } from 'lodash';
import cacheKeys from '../../configs/cache-keys.config';
import {OfflineManagerService} from '../shared/offline/offline-manager.service';
import {CommonService} from '../shared/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class SalePointsService {

  private CACHE_KEY = cacheKeys.salePoints;

  private salePoints: SalePoint[] = [];
  private salePointsTotal = 0;
  private salePointsSubject: BehaviorSubject<SalePoint[]>;

  constructor(private network: NetworkService,
              private commonService: CommonService,
              private cachingService: CachingService,
              private offlineManagerService: OfflineManagerService,
              private httpClient: HttpClient) {
    this.initService();
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Fetches sale points from the server
   */
  public async fetchSalePoints(): Promise<SalePoint[]> {
    try {
      if (this.network.isOnline()) {
        this.salePoints = await this.httpClient.get<SalePoint[]>(`${apiRoutes.salePoints}?order=customer_name`).toPromise();
      } else {
        const result: SalePoint[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.salePoints = result ? result : [];
      }
      this.updateSalePointsTotal();
      this.updateSalePointsSubject();
      return Promise.resolve(this.getSalePoints());
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Fetches sale points with basic info (id, name)
   * based on inspector id and category id from the server
   */
  public async fetchSalePointsFilteredWithBasicInfo(userId: number, categoryId: number): Promise<SalePoint[]> {
    try {
      if (this.network.isOnline()) {
        const url = `${apiRoutes.salePoints}?order=customer_name&select=id,customer_name&filter=inspector_id=${userId} AND category_id=${categoryId}`;
        this.salePoints = await this.httpClient.get<SalePoint[]>(url).toPromise();
      } else {
        const result: SalePoint[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.salePoints = result ? result : [];
      }
      this.updateSalePointsTotal();
      this.updateSalePointsSubject();
      return Promise.resolve(this.getSalePoints());
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Fetches user's own sale point
   * based on user id and category id from the server
   */
  public async fetchOwnSalePoint(userId: number, categoryId: number): Promise<SalePoint[]> {
    try {
      if (this.network.isOnline()) {
        const url = `${apiRoutes.salePoints}?filter=user_id=${userId} AND category_id=${categoryId}`;
        this.salePoints = await this.httpClient.get<SalePoint[]>(url).toPromise();
      } else {
        const result: SalePoint[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.salePoints = result ? result : [];
      }
      this.updateSalePointsTotal();
      this.updateSalePointsSubject();
      return Promise.resolve(this.getSalePoints());
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Fetches sale points from the server based on given parameters
   * @param refresh If data should be replaced or pushed to the existing data
   * @param amount The amount of data to return
   * @param skip The amount of data to skip
   * @param radius The radius of search area
   * @param geolocation The current location of the user
   * @param searchTerm The search term can be either A.F.M. or partial/full customer name
   */
  public async fetchSalePointsWithParams(
    refresh: boolean, amount: number, skip: number, radius: number, geolocation: any, searchTerm: string): Promise<SalePoint[]> {
    try {
      let result = null;
      if (this.network.isOnline()) {
        const hasGeolocation = (geolocation.latitude && geolocation.longitude);
        // Initial query
        let query = `${apiRoutes.salePoints}?count=true&limit=${amount}&skip=${skip}&order=customer_name`;
        if (hasGeolocation) {
          query += `&order=get_distance(latitude,longitude,${geolocation.latitude},${geolocation.longitude})`;
          // Radius parameter
          if (radius > -1) {
            query += `&filter=get_distance(latitude,longitude,${geolocation.latitude},${geolocation.longitude}) <= ${radius}`;
          }
        }
        // Search term parameter
        searchTerm = searchTerm.trim();
        if (!_isEmpty(searchTerm)) {
          query += `${(radius > -1 && hasGeolocation) ? ' AND ' : '&filter='}(vat_number='${searchTerm}' OR customer_name LIKE '%${searchTerm}%')`;
        }
        result = await this.httpClient.get(query).toPromise();
      } else {
        if (!_isEmpty(searchTerm)) {
          result = { value: [], total: 0 };
          result.value.push(...await this.cachingService.getLocalDataByField(this.CACHE_KEY, 'vat_number', searchTerm, true));
          result.value.push(...await this.cachingService.getLocalDataByField(this.CACHE_KEY, 'customer_name', searchTerm, true));
        } else {
          result = await this.cachingService.getLocalDataWithPagination(this.CACHE_KEY, amount, skip);
        }
      }
      if (result) {
        refresh ? this.salePoints = result.value : this.salePoints.push(...result.value);
        this.salePointsTotal = result.total;
      }
      this.updateSalePointsSubject();
      return Promise.resolve(this.getSalePoints());
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Fetches a sale point by id
   */
  public async getSalePoint(id: number): Promise<SalePoint> {
    try {
      let salePoint: SalePoint = null;
      if (this.network.isOnline()) {
        salePoint = await this.httpClient.get<SalePoint>(`${apiRoutes.salePoints}?first=true&filter=id=${id}`).toPromise();
      } else {
        salePoint = await this.cachingService.getLocalDataById(this.CACHE_KEY, id);
      }
      return Promise.resolve(salePoint);
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * POST a sale point
   */
  public async createSalePoint(salePoint: SalePoint): Promise<void> {
    try {
      let createdSalePoint: SalePoint = null;
      if (this.network.isOnline()) {
        createdSalePoint = await this.httpClient.post<SalePoint>(apiRoutes.salePoints, salePoint).toPromise();
      } else {
        await this.offlineManagerService.storeRequest(apiRoutes.salePoints, 'POST', salePoint);
        salePoint.id = this.commonService.generateRandomNumber(500000, 100000);
        createdSalePoint = salePoint;
      }
      this.addSalePoint(createdSalePoint);
      this.updateSalePointsTotal();
      this.updateSalePointsSubject();
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  /**
   * PUT a sale point
   */
  public async updateSalePoint(salePoint): Promise<any> {
    try {
      let updatedSalePoint: SalePoint = null;
      if (this.network.isOnline()) {
        updatedSalePoint = await this.httpClient.put<SalePoint>(apiRoutes.salePoints, salePoint).toPromise();
      } else {
        await this.offlineManagerService.storeRequest(apiRoutes.salePoints, 'PUT', salePoint);
        updatedSalePoint = salePoint;
      }
      this.replaceSalePoint(updatedSalePoint);
      this.updateSalePointsSubject();
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  /**
   * Returns sale points as an observable
   */
  public getSalePointsAsObservable(): Observable<SalePoint[]> {
    return this.salePointsSubject.asObservable();
  }

  /**
   * Returns sale points
   */
  public getSalePoints(): SalePoint[] {
    return _cloneDeep(this.salePoints);
  }

  /**
   * Returns sale points total
   */
  public getSalePointsTotal(): number {
    return this.salePointsTotal;
  }

  public resetState(): void {
    this.salePoints = [];
    this.updateSalePointsTotal();
    this.updateSalePointsSubject();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private initService() {
    this.salePointsSubject = new BehaviorSubject<SalePoint[]>([]);
  }

  private updateSalePointsTotal(): void {
    this.salePointsTotal = this.salePoints.length;
  }

  private updateSalePointsSubject(): void {
    this.salePointsSubject.next(_cloneDeep(this.salePoints));
  }

  private async addSalePoint(salePoint: SalePoint): Promise<void> {
    this.salePoints.push(salePoint);
    await this.cachingService.pushLocalData(this.CACHE_KEY, salePoint);
  }

  private async replaceSalePoint(salePoint: SalePoint): Promise<void> {
    this.salePoints = this.salePoints.map(entry => {
      return entry.id === salePoint.id ? salePoint : entry;
    });
    await this.cachingService.replaceLocalData(this.CACHE_KEY, salePoint);
  }
}
