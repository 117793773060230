import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';

import cacheKeys from '../../../configs/cache-keys.config';
import {CommonService} from '../common/common.service';

const STORAGE_KEY = cacheKeys.storedRequests;

interface StoredRequest {
  url: string;
  method: string;
  data: any;
  time: number;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class OfflineManagerService {

  constructor(private storage: Storage,
              private commonService: CommonService,
              private httpClient: HttpClient) {
  }

  // ================================================================================
  // Events
  // ================================================================================

  public async storeRequest(url: string, method: string, data: any): Promise<any> {

    const request: StoredRequest = {
      id: this.commonService.generateRandomAlphaNumericString(),
      time: Date.now(),
      url,
      method,
      data
    };

    try {
      const storedRequests = await this.getStoredRequests();
      storedRequests.push({...request});

      await this.storage.set(STORAGE_KEY, storedRequests);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  public async storedRequestsExist(): Promise<boolean> {
    try {
      const storedRequests = await this.getStoredRequests();
      return Promise.resolve(storedRequests.length > 0);
    } catch (e) {
      return Promise.reject();
    }
  }

  public async sendStoredRequests(): Promise<void> {
    try {
      const storedRequests = await this.getStoredRequests();
      const failedRequests = [];
      if (storedRequests.length > 0) {
        for (const req of storedRequests) {
          try {
            await this.httpClient.request(req.method, req.url, { body: req.data }).toPromise();
          } catch (e) {
            failedRequests.push(req);
          }
        }
      }
      failedRequests.length > 0 ? await this.storage.set(STORAGE_KEY, failedRequests) : await this.storage.remove(STORAGE_KEY);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private async getStoredRequests(): Promise<StoredRequest[]> {
    try {
      let storedRequests = await this.storage.get(STORAGE_KEY);
      if (!storedRequests) { storedRequests = []; }
      return Promise.resolve(storedRequests);
    } catch (e) {
      return Promise.reject();
    }
  }
}
