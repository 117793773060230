import {CommonService} from '../shared/common/common.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import apiRoutes from '../../configs/api-routes.config';
import cacheKeys from '../../configs/cache-keys.config';
import { IInventory as Inventory } from '../../models/inventory/Inventory.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {NetworkService} from '../shared/offline/network.service';
import { cloneDeep as _cloneDeep } from 'lodash';
import {CachingService} from '../shared/offline/caching.service';
import {OfflineManagerService} from '../shared/offline/offline-manager.service';

@Injectable({
  providedIn: 'root'
})

export class InventoriesService {

  private CACHE_KEY = cacheKeys.inventories;

  private inventories: Inventory[] = [];
  private inventoriesTotal = 0;
  private inventoriesSubject: BehaviorSubject<Inventory[]>;

  constructor(private commonService: CommonService,
              private network: NetworkService,
              private cachingService: CachingService,
              private offlineManagerService: OfflineManagerService,
              private httpClient: HttpClient) {
    this.initService();
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Fetches inventories from the server
   */
  public async fetchInventories(): Promise<Inventory[]> {
    try {
      if (this.network.isOnline()) {
        this.inventories = await this.httpClient.get<Inventory[]>(apiRoutes.inventories).toPromise();
      } else {
        const result: Inventory[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.inventories = result ? result : [];
      }
      this.updateInventoriesSubject();
      return Promise.resolve(this.getInventories());
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Fetches inventories from the server based on given parameters
   * @param refresh If data should be replaced or pushed to the existing data
   * @param amount The amount of data to return
   * @param skip The amount of data to skip
   */
  public async fetchInventoriesWithParams(
    refresh: boolean, amount: number, skip: number): Promise<Inventory[]> {
    try {
      let result = null;
      if (this.network.isOnline()) {
        const query = `${apiRoutes.inventories}?count=true&limit=${amount}&skip=${skip}`;
        result = await this.httpClient.get(query).toPromise();
      } else {
        result = await this.cachingService.getLocalDataWithPagination(this.CACHE_KEY, amount, skip);
      }
      if (result) {
        refresh ? this.inventories = result.value : this.inventories.push(...result.value);
        this.inventoriesTotal = result.total;
      }
      this.updateInventoriesSubject();
      return Promise.resolve(this.getInventories());
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Fetches an inventory by id
   */
  public async getInventory(id: number): Promise<Inventory> {
    try {
      let inventory: Inventory = null;
      if (this.network.isOnline()) {
        inventory = await this.httpClient.get<Inventory>(`${apiRoutes.inventories}?first=true&filter=id=${id}`).toPromise();
      } else {
        inventory = await this.cachingService.getLocalDataById(this.CACHE_KEY, id);
      }
      return Promise.resolve(inventory);
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Returns inventories as an observable
   */
  public getInventoriesAsObservable(): Observable<Inventory[]> {
    return this.inventoriesSubject.asObservable();
  }

  /**
   * Returns inventories
   */
  public getInventories(): Inventory[] {
    return _cloneDeep(this.inventories);
  }

  /**
   * Returns inventories total
   */
  public getInventoriesTotal(): number {
    return this.inventoriesTotal;
  }

  public resetState(): void {
    this.inventories = [];
    this.updateInventoriesTotal();
    this.updateInventoriesSubject();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private initService() {
    this.inventoriesSubject = new BehaviorSubject<Inventory[]>([]);
  }

  private updateInventoriesTotal(): void {
    this.inventoriesTotal = this.inventories.length;
  }

  private updateInventoriesSubject(): void {
    this.inventoriesSubject.next(_cloneDeep(this.inventories));
  }

  private async addInventory(inventory: Inventory): Promise<void> {
    this.inventories.push(inventory);
    await this.cachingService.pushLocalData(this.CACHE_KEY, inventory);
  }

  private async replaceInventory(inventory: Inventory): Promise<void> {
    this.inventories = this.inventories.map(entry => {
      return entry.id === inventory.id ? inventory : entry;
    });
    await this.cachingService.replaceLocalData(this.CACHE_KEY, inventory);
  }
}
