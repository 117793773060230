import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderController {

  private headerController = new Subject();
  private headerActions = new Subject();
  private loadingState: Subject<boolean> = new Subject();

  constructor() {
  }

  getStream() {
    return this.headerController.asObservable();
  }

  onLoadingStateChange() {
    return this.loadingState.asObservable();
  }

  setHeaderOptions(options: HeaderOptions) {
    this.headerController.next(options);
  }

  setLoadingState(state: boolean = false) {
    this.loadingState.next(state);
  }

  emitAction(data: any) {
    this.headerActions.next(data);
  }

  listenToActions() {
    return this.headerActions.asObservable();
  }
}

export interface HeaderOptions {
  title?: string;
  showHeader?: boolean;
  showMenuToggle?: boolean;
  showLogo?: boolean;
  showLoading?: boolean;
  action?: any;
}
