import {CommonService} from '../../../../shared/services/shared/common/common.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../../../../shared/services/shared/common/loading.service';
import {ModalController} from '@ionic/angular';
import {VatNumberValidators} from './vat-number.validators';
import {Dialogs} from '@ionic-native/dialogs/ngx';
import {SalePointsService} from '../../../../shared/services/sale-points/sale-points.service';
import {ToastService} from '../../../../shared/services/shared/common/toast.service';
import toastMessages from '../../../../shared/configs/toast-messages.config';
import {ConnectionStatus, NetworkService} from '../../../../shared/services/shared/offline/network.service';
import {Subscription} from 'rxjs';

@Component({
  templateUrl: './create-sale-point-modal.component.html',
  styleUrls: ['./create-sale-point-modal.component.scss'],
})

export class CreateSalePointModalComponent implements OnInit, OnDestroy {
  @Input() position: any;

  private subscription: Subscription;
  isNetworkOnline = true;

  public invalidVatNumber: boolean;
  public salePointForm: FormGroup;
  pendingRequest = false;

  constructor(public commonService: CommonService,
              private dialogs: Dialogs,
              private networkService: NetworkService,
              private salePointsService: SalePointsService,
              private toastService: ToastService,
              private httpClient: HttpClient,
              private loadingService: LoadingService,
              private modalController: ModalController,
              private vatNumberValidators: VatNumberValidators) {
  }

  // ================================================================================
  // Lifecycle
  // ================================================================================

  ngOnInit(): void {
    this.subscribeToNetworkChanges();
    this.salePointForm = new FormGroup({
      latitude: new FormControl(this.position.latitude, Validators.required),
      longitude: new FormControl(this.position.longitude, Validators.required),
      name: new FormControl('', Validators.required),
      street_address: new FormControl(''),
      street_number: new FormControl(''),
      postal_area_description: new FormControl(''),
      vat_number: new FormControl('', [
        Validators.minLength(9),
        Validators.required,
        this.vatNumberValidators.invalidVatNumber
      ])
    });

    this.salePointForm.get('vat_number').valueChanges.subscribe((value) => {
      if (this.salePointForm.get('vat_number').valid) {
        this.invalidVatNumber = false;

        this.httpClient.get(`sale_point?filter=vat_number='${value}'`).toPromise()
          .then((companies: any[]) => {
            if (companies.length > 0) {
              // this.invalidVatNumber = true;
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

  // ================================================================================
  // Events
  // ================================================================================

  public clearFields(): void {
    this.salePointForm.get('name').setValue('');

    this.salePointForm.get('street_address').setValue('');

    this.salePointForm.get('street_number').setValue('');

    this.salePointForm.get('postal_area_description').setValue('');
  }

  public enableSearchButton(): boolean {
    return !(this.salePointForm.get('vat_number').valid && !this.invalidVatNumber);
  }

  public enableSubmitButton(): boolean {
    return !this.salePointForm.valid;
  }

  public async insertSalePoint(): Promise<void> {
    const salePoint = {
      address: `${this.salePointForm.get('street_address').value} ${this.salePointForm.get('street_number').value}`,
      customer_name: this.salePointForm.get('name').value,
      latitude: this.salePointForm.get('latitude').value,
      longitude: this.salePointForm.get('longitude').value,
      vat_number: this.salePointForm.get('vat_number').value,
      city: this.salePointForm.get('postal_area_description').value
    };

    this.pendingRequest = true;
    try {
      await this.salePointsService.createSalePoint(salePoint);
      this.toastService.present(toastMessages.salePoints.success.created);
      await this.modalController.dismiss();
    } catch (e) {
      this.toastService.present(toastMessages.common.error);
    }
    this.pendingRequest = false;
  }

  public searchSalePoint(): void {
    this.loadingService.show();

    this.httpClient.get(`sale_point/taxis?vatNumber=${this.salePointForm.get('vat_number').value}`, {observe: 'response'})
      .subscribe((data: any) => {

        if (data.status === 200) {
          this.salePointForm.get('name').setValue(data.body.name);

          this.salePointForm.get('street_address').setValue(data.body.street_address);

          this.salePointForm.get('street_number').setValue(data.body.street_number);

          this.salePointForm.get('postal_area_description').setValue(data.body.postal_area_description);

          this.loadingService.hide();
        } else {
          this.loadingService.hide();
          this.dialogs.alert(`${data.body.error_description}`, 'Αποτυχία Εύρεσης ΑΦΜ', 'OK').then(() => {
            this.clearFields();
          });
        }
      });
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private subscribeToNetworkChanges() {
    this.subscription = (this.networkService.onNetworkChange().subscribe(state => {
      this.isNetworkOnline = (state === ConnectionStatus.Online);
    }));
  }
}
