import {LayoutComponent} from './components/layout/layout.component';
import {MainGuard} from './shared/guards/main.guard';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginPageModule'
    },
    {
        path: '',
        component: LayoutComponent,
        canActivateChild: [MainGuard],
        children: [
            {
                path: 'home',
                loadChildren: './pages/home/home.module#HomePageModule'
            },
            {
                path: 'inventories',
                loadChildren: './pages/inventories/inventories-listing/inventories-listing.module#InventoriesListingPageModule'
            },
            {
                path: 'inventory/:id',
                loadChildren: './pages/inventory/inventory.module#InventoryPageModule'
            },
            {
                path: 'sale-points',
                loadChildren: './pages/sale-points/sale-points-listing/sale-points-listing.module#SalePointsListingPageModule'
            },
            {
                path: 'load-requests',
                loadChildren: './pages/load-unload/load-requests/load-requests.module#LoadRequestsPageModule'
            },
            {
                path: 'unload-requests',
                loadChildren: './pages/load-unload/unload-requests/unload-requests.module#UnloadRequestsPageModule'
            },
            {   path: 'refrigerator-orders',
                loadChildren: './pages/refrigerator/orders/refrigerator-orders-listing/refrigerator-orders-listing.module#RefrigeratorOrdersListingPageModule'
            },
            {   path: 'refrigerator-orders/:id',
                loadChildren: './pages/refrigerator/orders/refrigerator-orders-single/refrigerator-orders-single.module#RefrigeratorOrdersSinglePageModule'
            },
            {   path: 'refrigerator-return-requests',
                loadChildren: './pages/refrigerator/returns/refrigerator-return-requests-listing/refrigerator-return-requests-listing.module#RefrigeratorReturnRequestsListingPageModule'
            },
            {   path: 'refrigerator-return-requests/:id',
                loadChildren: './pages/refrigerator/returns/refrigerator-return-requests-single/refrigerator-return-requests-single.module#RefrigeratorReturnRequestsSinglePageModule'
            }
        ]
    },
    {
        path: 'sale-points/:id',
        canActivate: [MainGuard],
        loadChildren: './pages/sale-point/sale-point.module#SalePointPageModule'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
