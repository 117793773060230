import {AlertController} from '@ionic/angular';
import {ErrorHandler, Injectable} from '@angular/core';
import {LoadingService} from './shared/services/shared/common/loading.service';
import {MatDialog} from '@angular/material';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    constructor(public alertController: AlertController,
                private dialog: MatDialog,
                private loadingService: LoadingService) {
    }

    handleError(error) {
        console.log(error);
        this.loadingService.hide();
        this.presentAlert(error);
    }

    async presentAlert(error: any) {
        const alert = await this.alertController.create({
            header: 'Σφάλμα',
            message: `Συνέβη σφάλμα κατά τη διάρκεια εκτέλεσης της διεργασίας. ${error.message}`,
            buttons: [{
                text: 'OK',
                role: 'cancel'
            }]
        });

        await alert.present();
    }
}
