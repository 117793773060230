import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class LoadingService {
  public loadingState: boolean;
  private loading: any;

  constructor(private loadingController: LoadingController) {
    this.loadingState = false;
  }

  async initializeLoading(message) {
    if (!this.loadingState) {
      this.loadingState = true;
      this.loading = await this.loadingController.create({
        message
      });
    }
  }

  public hide(): void {
    if (this.loadingState) {
      setTimeout(() => {
        if (this.loading) { this.loading.dismiss(); }
        this.loadingState = false;
      }, 1000);
    }
  }

  public show(message = 'Παρακαλώ περιμένετε'): void {
    if (!this.loadingState) {
      this.initializeLoading(message).then(() => {
        this.loading.present();
      });
    }
  }
}
