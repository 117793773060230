import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

import {
  cloneDeep as _cloneDeep,
  find as _find,
  slice as _slice,
  isArray as _isArray,
  isString as _isString,
  reject as _reject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CachingService {

  constructor(private storage: Storage) {}

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Store data to local storage
   * @param key The name of data store
   * @param data The data to be stored
   */
  public async setLocalData(key: string, data: any): Promise<void> {
    try {
      await this.storage.set(key, data);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Push data to local storage
   * @param key The name of data store
   * @param data The data to be pushed
   */
  public async pushLocalData(key: string, data: any): Promise<void> {
    try {
      if (data) {
        let localData = await this.getLocalData(key);
        if (!localData) { localData = []; }
        _isArray(data) ? localData.push(...data) : localData.push(data);
        await this.setLocalData(key, localData);
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Replace data in local storage
   * @param key The name of data store
   * @param data The data to be replaced
   */
  public async replaceLocalData(key: string, data: any): Promise<void> {
    try {
      if (data) {
        let localData = await this.getLocalData(key);
        if (!localData) {
          localData = [data];
        } else {
          localData = localData.map(entry => {
            return entry.id === data.id ? data : entry;
          });
        }
        await this.setLocalData(key, localData);
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Removes data from local storage
   * @param key The name of data store
   * @param field The field to query for
   * @param value The value of the field
   */
  public async removeLocalData(key: string, field: string, value: any): Promise<void> {
    try {
      if (value) {
        let localData = await this.getLocalData(key);
        if (localData) {
          localData = _reject(localData, [field, value]);
          await this.setLocalData(key, localData);
        }
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Get data from local storage
   * @param key The name of data store
   */
  public async getLocalData(key: string): Promise<any> {
    try {
      return await this.storage.get(key);
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Get data from local storage by id
   * @param key The name of data store
   * @param id Entity's id
   */
  public async getLocalDataById(key: string, id: string | number): Promise<any> {
    try {
      const data = await this.storage.get(key);
      return _cloneDeep(_find(data, ['id', id]));
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Get data from local storage by field
   * @param key The name of data store
   * @param field The name of the field
   * @param value The value of the field
   * @param multiple If the expected data must be single or multiple
   */
  public async getLocalDataByField(key: string, field: string, value: any, multiple: boolean = false): Promise<any> {
    try {
      const data = await this.storage.get(key);
      if (!multiple) {
        return _cloneDeep(_find(data, [field, value]));
      } else {
        return _cloneDeep(data.filter(entry => {
          if (_isString(entry[field])) {
            return entry[field].toLowerCase().includes(value.toLowerCase());
          } else {
            return entry[field] === value;
          }
        }));
      }
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Get data from local storage with pagination support
   * @param key The name of data store
   * @param amount Amount of data to be retrieved
   * @param skip Amount of data to be skipped
   */
  public async getLocalDataWithPagination(key: string, amount: number, skip: number): Promise<any> {
    try {
      const data = await this.storage.get(key);
      return {
        total: data.length,
        value: _slice(data, skip, skip + amount)
      };
    } catch (e) {
      return Promise.reject();
    }
  }

}
