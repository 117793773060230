import {Injectable} from '@angular/core';

import {SalePointsService} from '../../sale-points/sale-points.service';
import {RefrigeratorsService} from '../../refrigerators/refrigerators.service';
import {InventoriesService} from '../../inventories/inventories.service';
import {CachingService} from './caching.service';
import cacheKeys from '../../../configs/cache-keys.config';
import {LoadRequestsService} from '../../load-unload/load-requests.service';
import {UnloadRequestsService} from '../../load-unload/unload-requests.service';
import {AuthService} from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataDownloadService {

  constructor(private authService: AuthService,
              private cachingService: CachingService,
              private salePointService: SalePointsService,
              private refrigeratorsService: RefrigeratorsService,
              private loadRequestsService: LoadRequestsService,
              private unloadRequestsService: UnloadRequestsService,
              private inventoriesService: InventoriesService) {
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Fetches all data from the server and caches it locally
   */
  public async downloadAllData(): Promise<void> {
    try {
      const userRole: string = this.authService.getUserRole();
      let salePoints = []
        , refrigerators = []
        , inventories = []
        , loadRequests = []
        , unloadRequests = [];

      // Fetch data
      const requests = [];
      switch (userRole) {
        case 'driver':
        case 'ownNetworkRepresentative':
        case 'ownNetworkDistributor':
        case 'salesInspector':
        case 'technician':
        case 'regionalDirector':
          requests.push(this.salePointService.fetchSalePoints());
          requests.push(this.refrigeratorsService.fetchRefrigerators());
          requests.push(this.inventoriesService.fetchInventories());
          [salePoints, refrigerators, inventories] = await Promise.all([...requests]);
          break;
        case 'districtRepresentative':
          requests.push(this.salePointService.fetchSalePoints());
          requests.push(this.refrigeratorsService.fetchRefrigerators());
          requests.push(this.inventoriesService.fetchInventories());
          requests.push(this.loadRequestsService.fetchLoadRequests());
          requests.push(this.unloadRequestsService.fetchUnloadRequests());
          [salePoints, refrigerators, inventories, loadRequests, unloadRequests] = await Promise.all([...requests]);
          break;
        case 'manager3PL':
        case 'receiptPointOfficer':
          requests.push(this.refrigeratorsService.fetchRefrigerators());
          requests.push(this.unloadRequestsService.fetchUnloadRequests());
          [refrigerators, unloadRequests] = await Promise.all([...requests]);
          break;
        case 'manufacturingCompanyRepresentative':
          requests.push(this.refrigeratorsService.fetchRefrigerators());
          requests.push(this.loadRequestsService.fetchLoadRequests());
          [refrigerators, loadRequests] = await Promise.all([...requests]);
          break;
        case 'warehouseManager':
          requests.push(this.refrigeratorsService.fetchRefrigerators());
          requests.push(this.loadRequestsService.fetchLoadRequests());
          requests.push(this.unloadRequestsService.fetchUnloadRequests());
          [refrigerators, loadRequests, unloadRequests] = await Promise.all([...requests]);
      }

      // Cache data
      const cacheRequests = [];
      cacheRequests.push(this.cachingService.setLocalData(cacheKeys.salePoints, salePoints));
      cacheRequests.push(this.cachingService.setLocalData(cacheKeys.refrigerators, refrigerators));
      cacheRequests.push(this.cachingService.setLocalData(cacheKeys.inventories, inventories));
      cacheRequests.push(this.cachingService.setLocalData(cacheKeys.loadRequests, loadRequests));
      cacheRequests.push(this.cachingService.setLocalData(cacheKeys.unloadRequests, unloadRequests));
      await Promise.all([...cacheRequests]);

      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

}
