import * as moment from 'moment';

function currentISOStringDate() {
  return moment().toISOString();
}

function formatDateStringToISOString(date) {
  if (!date) { return date; }
  const d = moment(date);
  if (d.isValid()) {
    return d.toISOString();
  } else {
    return date;
  }
}

export { formatDateStringToISOString, currentISOStringDate };
