import { AppComponent } from './app.component';
import { AppErrorHandler } from './app-error-handler';
import { AppRoutingModule } from './app-routing.module';
import {AuthInterceptor} from './shared/interceptors/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {CreateSalePointModalComponent} from './components/shared/table/create-sale-point-modal/create-sale-point-modal.component';
import { ErrorHandler, NgModule } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {InventoryRefrigeratorsComponent} from './components/shared/table/inventory-refrigerators/inventory-refrigerators.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {LayoutComponent} from './components/layout/layout.component';
import { RouteReuseStrategy } from '@angular/router';
import {SharedModule} from './shared/modules/shared.module';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NFC, Ndef } from '@ionic-native/nfc/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { Network } from '@ionic-native/network/ngx';
import {IonicStorageModule} from '@ionic/storage';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        CreateSalePointModalComponent,
        InventoryRefrigeratorsComponent,
        LayoutComponent
    ],
    entryComponents: [
        CreateSalePointModalComponent,
        InventoryRefrigeratorsComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        SharedModule
    ],
    providers: [
        Geolocation,
        SplashScreen,
        NFC,
        Ndef,
        Dialogs,
        OpenNativeSettings,
        Network,
        StatusBar,
        {
            provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
        },
        {
            provide: ErrorHandler, useClass: AppErrorHandler
        },
        {
            provide: RouteReuseStrategy, useClass: IonicRouteStrategy
        }
    ]
})

export class AppModule {
}
