import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {LoadRequest, IToLoad, ILoadedRefrigerator } from '../../models/load-unload/LoadRequest.model';
import {groupBy as _groupBy, find as _find, filter as _filter, cloneDeep as _cloneDeep} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {NetworkService} from '../shared/offline/network.service';
import {CachingService} from '../shared/offline/caching.service';
import {OfflineManagerService} from '../shared/offline/offline-manager.service';
import apiRoutes from '../../configs/api-routes.config';
import cacheKeys from '../../configs/cache-keys.config';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LoadRequestsService {

  private CACHE_KEY = cacheKeys.loadRequests;

  private loadRequests: LoadRequest[] = [];

  private pendingLoadRequests: LoadRequest[] = [];
  private pendingLoadRequestsSubject: BehaviorSubject<LoadRequest[]>;

  private toSendLoadRequests: LoadRequest[] = [];
  private toSendLoadRequestsSubject: BehaviorSubject<LoadRequest[]>;

  private sentLoadRequests: LoadRequest[] = [];
  private sentLoadRequestsSubject: BehaviorSubject<LoadRequest[]>;

  constructor(private httpClient: HttpClient,
              private network: NetworkService,
              private cachingService: CachingService,
              private offlineManagerService: OfflineManagerService) {
    this.initService();
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Fetches load requests from the server
   */
  public async fetchLoadRequests(): Promise<LoadRequest[]> {
    try {
      if (this.network.isOnline()) {
        this.loadRequests = await this.httpClient.get<LoadRequest[]>(apiRoutes.loadRequests).toPromise();
        await this.cachingService.setLocalData(this.CACHE_KEY, this.loadRequests);
      } else {
        const result: LoadRequest[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.loadRequests = result ? result : [];
      }
      this.groupAndUpdateLoadRequests();
      return Promise.resolve(this.getLoadRequests());
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Fetches a load request by id
   */
  public async fetchLoadRequestById(id: number): Promise<LoadRequest> {
    try {
      let loadRequest: LoadRequest = null;
      if (this.network.isOnline()) {
        loadRequest = await this.httpClient.get<LoadRequest>(`${apiRoutes.loadRequests}/${id}`).toPromise();
        this.replaceLoadRequest(loadRequest);
        this.groupAndUpdateLoadRequests();
      } else {
        loadRequest = await this.cachingService.getLocalDataById(this.CACHE_KEY, id);
      }
      return Promise.resolve(loadRequest);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * PUT a load request
   */
  public async updateLoadRequest(loadRequest: LoadRequest, stage_id: number): Promise<LoadRequest> {
    try {
      const serverLoadRequest = await this.fetchLoadRequestById(loadRequest.id);
      loadRequest = this.filterOnlyUpdatedRefrigerators(loadRequest, serverLoadRequest, stage_id);
      if (stage_id === 3) { loadRequest = this.populateDeliveryDate(loadRequest); }
      let updatedLoadRequest: LoadRequest = null;
      if (this.network.isOnline()) {
        updatedLoadRequest =
          await this.httpClient
            .put<LoadRequest>(`${apiRoutes.loadRequests}/${loadRequest.id}?stage_id=${stage_id}`, loadRequest).toPromise();
      } else {
        await this.offlineManagerService
          .storeRequest(`${apiRoutes.loadRequests}/${loadRequest.id}?stage_id=${stage_id}`, 'PUT', loadRequest);
        updatedLoadRequest = loadRequest;
      }
      this.replaceLoadRequest(updatedLoadRequest);
      this.groupAndUpdateLoadRequests();
      return Promise.resolve(updatedLoadRequest);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  /**
   * Returns load requests
   */
  public getLoadRequests(): LoadRequest[] {
    return _cloneDeep(this.loadRequests);
  }

  /**
   * Returns pending load requests as an observable
   */
  public getPendingLoadRequests(): Observable<LoadRequest[]> {
    return this.pendingLoadRequestsSubject.asObservable();
  }

  /**
   * Returns to send load requests as an observable
   */
  public getToSendLoadRequests(): Observable<LoadRequest[]> {
    return this.toSendLoadRequestsSubject.asObservable();
  }

  /**
   * Returns sent load requests as an observable
   */
  public getSentLoadRequests(): Observable<LoadRequest[]> {
    return this.sentLoadRequestsSubject.asObservable();
  }

  public resetState(): void {
    this.loadRequests = [];
    this.groupAndUpdateLoadRequests();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private async replaceLoadRequest(loadRequest: LoadRequest): Promise<void> {
    if (loadRequest) {
      this.loadRequests = this.loadRequests.map(entry => {
        return entry.id === loadRequest.id ? loadRequest : entry;
      });
      await this.cachingService.replaceLocalData(this.CACHE_KEY, loadRequest);
    }
  }

  private groupAndUpdateLoadRequests(): void {
    this.groupLoadRequestsByStatusId(this.loadRequests);
    this.updateLoadRequestsSubject('all');
  }

  private updateLoadRequestsSubject(type: string): void {
    switch (type) {
      case 'pending':
        this.pendingLoadRequestsSubject.next(this.pendingLoadRequests);
        break;
      case 'toSend':
        this.toSendLoadRequestsSubject.next(this.toSendLoadRequests);
        break;
      case 'sent':
        this.sentLoadRequestsSubject.next(this.sentLoadRequests);
        break;
      case 'all':
        this.pendingLoadRequestsSubject.next(this.pendingLoadRequests);
        this.toSendLoadRequestsSubject.next(this.toSendLoadRequests);
        this.sentLoadRequestsSubject.next(this.sentLoadRequests);
    }
  }

  /**
   * Groups load requests based on their status id
   * Status ID 1: Belongs to pending requests
   * Status ID 2: Belongs from one up to all categories,
   *              if at least one loaded refrigerator stage id matches that category status id
   * Status ID 3: Belongs to sent requests
   */
  private groupLoadRequestsByStatusId(loadRequests: LoadRequest[]): void {
    const groupedByStatusId = _groupBy(loadRequests, 'status_id');
    this.pendingLoadRequests = groupedByStatusId['1'] ? groupedByStatusId['1'] : [];
    this.sentLoadRequests = [];
    if (groupedByStatusId['3']) { this.sentLoadRequests.push(...groupedByStatusId['3']); }
    if (groupedByStatusId['4']) { this.sentLoadRequests.push(...groupedByStatusId['4']); }
    this.toSendLoadRequests = [];

    if (!groupedByStatusId['2']) { return; }
    // For each load request on stage id 2
    groupedByStatusId['2'].forEach((request: LoadRequest) => {
      let belongsToPending = false;
      let belongsToToSend = false;
      let belongsToSent = false;

      // Check each load order for refrigerators in any of the 3 stages
      request.details.forEach((entry: IToLoad) => {
        if (entry.total_quantity > entry.quantity_executed) { belongsToPending = true; }
        const groupedByStageId = _groupBy(entry.serial_numbers, 'stage_id');
        if (groupedByStageId['1']) { belongsToPending = true; }
        if (groupedByStageId['2']) { belongsToToSend = true; }
        if (groupedByStageId['3']) { belongsToSent = true; }
      });

      // Push the load request based on check results
      if (belongsToPending) { this.pendingLoadRequests.push(request); }
      if (belongsToToSend) { this.toSendLoadRequests.push(request); }
      if (belongsToSent) { this.sentLoadRequests.push(request); }
    });
  }

  /**
   * Filters the refrigerators of the load request, keeping only the ones that are updated
   * Stage ID 1: Keeps all refrigerators with stage id 1 which are newly added to the request
   * Stage ID 2: Keeps all refrigerators with stage id 1
   * Stage ID 3: Keeps all refrigerators with state id 2
   */
  private filterOnlyUpdatedRefrigerators(
    localLoadRequest: LoadRequest, serverLoadRequest: LoadRequest, stage: number): LoadRequest {
    const filteredLoadRequest: LoadRequest = _cloneDeep(localLoadRequest);

    switch (stage) {
      case 1:
      case 2:
        filteredLoadRequest.details.forEach((entry: IToLoad, index) => {
          entry.serial_numbers = entry.serial_numbers.filter((refrigerator: ILoadedRefrigerator) => {
            if (serverLoadRequest.details[index]) {
              return !_find(serverLoadRequest.details[index].serial_numbers,
                { stage_id: refrigerator.stage_id, serial_number: refrigerator.serial_number});
            } else {
              return true;
            }
          });
        });
        break;
      case 3:
        filteredLoadRequest.details.forEach((entry: IToLoad) => {
          entry.serial_numbers = _filter(entry.serial_numbers, ['stage_id', stage - 1]);
        });
        break;
    }

    return filteredLoadRequest;
  }

  private populateDeliveryDate(loadRequest: LoadRequest): LoadRequest {
    const timestamp = moment().toISOString();
    loadRequest.details.forEach((entry: IToLoad) => {
      entry.serial_numbers.forEach((refrigerator: ILoadedRefrigerator) => refrigerator.delivery_date = timestamp);
    });
    return loadRequest;
  }

  private initService() {
    this.pendingLoadRequestsSubject = new BehaviorSubject<LoadRequest[]>([]);
    this.toSendLoadRequestsSubject = new BehaviorSubject<LoadRequest[]>([]);
    this.sentLoadRequestsSubject = new BehaviorSubject<LoadRequest[]>([]);
  }
}
