import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';

import {BehaviorSubject, Observable} from 'rxjs';
import { Network } from '@ionic-native/network/ngx';

export enum ConnectionStatus {
  Offline,
  Online
}

@Injectable(
  {
  providedIn: 'root'
}
)
export class NetworkService {

  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Online);

  constructor(private platform: Platform,
              private network: Network) {
    this.initService();
  }

  // ================================================================================
  // Events
  // ================================================================================

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  public isOnline(): boolean {
    return this.status.getValue() === ConnectionStatus.Online;
  }

  public isOffline(): boolean {
    return this.status.getValue() === ConnectionStatus.Offline;
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private initService() {
    this.platform.ready().then(() => {
      this.initNetworkEvents();
      const status =  this.network.type !== 'none' ? ConnectionStatus.Online : ConnectionStatus.Offline;
      this.updateNetworkStatus(status);
    });
  }

  private initNetworkEvents() {
    // On disconnect
    this.network.onDisconnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.Online) {
        this.updateNetworkStatus(ConnectionStatus.Offline);
      }
    });
    // On connect
    this.network.onConnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.Offline) {
        this.updateNetworkStatus(ConnectionStatus.Online);
      }
    });
  }

  private updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
    // this.status.next(ConnectionStatus.Offline);
  }
}
