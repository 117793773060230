import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgModule} from '@angular/core';

import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxMaskModule} from 'ngx-mask';

import {MaterialModule} from './material.module';
import {FontawesomeIconsModule} from './fontawesome-icons.module';

import {DetailsComponent} from '../../components/shared/details/details.component';
import {TableComponent} from '../../components/shared/table/table.component';
import {RefrigeratorModalComponent} from '../../components/refrigerators/refrigerator-modal/refrigerator-modal.component';
import {HeaderComponent} from '../../components/shared/header/header.component';
import {SpinnerComponent} from '../../components/shared/spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    IonicModule,
    MaterialModule,
    NgxMaskModule.forRoot({}),
    ReactiveFormsModule,
    FontawesomeIconsModule
  ],
  declarations: [
    DetailsComponent,
    RefrigeratorModalComponent,
    TableComponent,
    HeaderComponent,
    SpinnerComponent
  ],
  entryComponents: [RefrigeratorModalComponent],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    IonicModule,
    MaterialModule,
    ReactiveFormsModule,
    FontawesomeIconsModule,
    DetailsComponent,
    RefrigeratorModalComponent,
    TableComponent,
    HeaderComponent,
    SpinnerComponent
  ]
})

export class SharedModule {
}
