import {Injectable} from '@angular/core';

import {SalePointsService} from '../../sale-points/sale-points.service';
import {RefrigeratorsService} from '../../refrigerators/refrigerators.service';
import {LoadRequestsService} from '../../load-unload/load-requests.service';
import {UnloadRequestsService} from '../../load-unload/unload-requests.service';
import {InventoriesService} from '../../inventories/inventories.service';
import {RefrigeratorOrdersService} from '../../refrigerators/refrigerator-orders.service';
import {RefrigeratorReturnRequestsService} from '../../refrigerators/refrigerator-return-requests.service';

@Injectable({
  providedIn: 'root'
})
export class StateManagerService {

  constructor(private salePointService: SalePointsService,
              private refrigeratorsService: RefrigeratorsService,
              private refrigeratorOrdersService: RefrigeratorOrdersService,
              private refrigeratorReturnRequestsService: RefrigeratorReturnRequestsService,
              private inventoriesService: InventoriesService,
              private loadRequestsService: LoadRequestsService,
              private unloadRequestsService: UnloadRequestsService) {
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Resets the state application wide
   */
  public async resetApplicationState(): Promise<void> {
    try {
      this.salePointService.resetState();
      this.refrigeratorsService.resetState();
      this.inventoriesService.resetState();
      this.loadRequestsService.resetState();
      this.unloadRequestsService.resetState();
      this.refrigeratorOrdersService.resetState();
      this.refrigeratorReturnRequestsService.resetState();
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Resets all state accessible by user's role
   */
  public async resetStateBasedOnUserRole(userRole): Promise<void> {
    try {
      switch (userRole) {
        case 'driver':
        case 'ownNetworkRepresentative':
        case 'ownNetworkDistributor':
        case 'salesInspector':
        case 'technician':
        case 'regionalDirector':
          this.salePointService.resetState();
          this.refrigeratorsService.resetState();
          this.inventoriesService.resetState();
          this.refrigeratorOrdersService.resetState();
          this.refrigeratorReturnRequestsService.resetState();
          break;
        case 'districtRepresentative':
          this.salePointService.resetState();
          this.refrigeratorsService.resetState();
          this.inventoriesService.resetState();
          this.loadRequestsService.resetState();
          this.unloadRequestsService.resetState();
          this.refrigeratorReturnRequestsService.resetState();
          break;
        case 'manager3PL':
        case 'receiptPointOfficer':
          this.refrigeratorsService.resetState();
          this.unloadRequestsService.resetState();
          break;
        case 'manufacturingCompanyRepresentative':
          this.refrigeratorsService.resetState();
          this.loadRequestsService.resetState();
          break;
        case 'warehouseManager':
          this.refrigeratorsService.resetState();
          this.loadRequestsService.resetState();
          this.unloadRequestsService.resetState();
          this.refrigeratorReturnRequestsService.resetState();
          break;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }
}
