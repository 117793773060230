import {Component, OnDestroy, OnInit} from '@angular/core';

import {HeaderController, HeaderOptions} from '../../../shared/services/shared/navigation/header-controller.service';
import {Subscription} from 'rxjs';
import {NavigationService} from '../../../shared/services/shared/navigation/navigation.service';
import {NetworkService, ConnectionStatus} from '../../../shared/services/shared/offline/network.service';
import {OfflineManagerService} from '../../../shared/services/shared/offline/offline-manager.service';
import {LoadingService} from '../../../shared/services/shared/common/loading.service';
import {ToastService} from '../../../shared/services/shared/common/toast.service';
import toastMessages from '../../../shared/configs/toast-messages.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  networkStatus: number = ConnectionStatus.Offline;
  networkStates = ConnectionStatus;
  showLoading = false;
  options: HeaderOptions = {
    showHeader: true,
    showLogo: false,
    showMenuToggle: true,
    title: ''
  };
  action: any = null;

  constructor(private headerController: HeaderController,
              private networkService: NetworkService,
              private offlineManager: OfflineManagerService,
              private loadingService: LoadingService,
              private toastService: ToastService,
              private navigationService: NavigationService) {
  }

  // ================================================================================
  // Lifecycle
  // ================================================================================

  ngOnInit() {
    this.initComponent();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe() );
  }

  // ================================================================================
  // Events
  // ================================================================================

  onActionClick() {
    if (this.action) {
      this.headerController.emitAction(this.action.type);
    }
  }

  onGoBack() {
    this.navigationService.goBack();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private initComponent() {
    this.subscribeToHeaderController();
    this.subscribeToNetworkChanges();
    this.subscribeToLoadingChanges();
  }

  private subscribeToHeaderController() {
    this.subscriptions.push(this.headerController.getStream().subscribe((options: HeaderOptions) => {
      this.options = {
        showHeader: true,
        showMenuToggle: true,
        showLogo: false,
        title: '',
        ...options
      };
      this.action = options.action ? options.action : null;
    }));
  }

  private subscribeToNetworkChanges() {
    this.subscriptions.push(this.networkService.onNetworkChange().subscribe(state => {
      this.networkStatus = state;
      if (state === this.networkStates.Online) { this.checkOfflineManager(); }
    }));
  }

  private subscribeToLoadingChanges() {
    this.subscriptions.push(this.headerController.onLoadingStateChange().subscribe(state => this.showLoading = state ));
  }

  /**
   * Checks offline manager for stored requests. If they exist, it sends them
   */
  private async checkOfflineManager() {
    try {
      const hasRequests = await this.offlineManager.storedRequestsExist();
      if (hasRequests) {
        this.loadingService.show(toastMessages.offlineManager.loading);
        await this.offlineManager.sendStoredRequests();
        this.toastService.present(toastMessages.offlineManager.success);
      }
    } catch (e) {
      this.toastService.present(toastMessages.offlineManager.error);
    }
    this.loadingService.hide();
  }
}
