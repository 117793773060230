import {AlertController, MenuController} from '@ionic/angular';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../../shared/services/auth/auth.service';
import * as RoleAccess from '../../shared/configs/role-access.config';
import {Route} from '../../shared/configs/role-access.config';
import toastMessages from '../../shared/configs/toast-messages.config';
import {LoadingService} from '../../shared/services/shared/common/loading.service';
import {ToastService} from '../../shared/services/shared/common/toast.service';
import {ConnectionStatus, NetworkService} from '../../shared/services/shared/offline/network.service';
import {DataDownloadService} from '../../shared/services/shared/offline/dataDownload.service';
import {IUSer} from '../../shared/models/user/User.model';

@Component({
  selector: 'app-main-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit {
  public activeRoute: any;
  public pendingComponentInit: boolean;
  public user: IUSer;
  routes: Route[] = [];

  constructor(public alertController: AlertController,
              private authService: AuthService,
              private network: NetworkService,
              private menuController: MenuController,
              private loadingService: LoadingService,
              private toastService: ToastService,
              private dataDownloadService: DataDownloadService,
              private router: Router) {
  }

  // ================================================================================
  // Lifecycle
  // ================================================================================

  ngOnInit(): void {
    this.authService.getUser().then((user) => {
      this.user = user;

      // Populate sidebar with allowed routes based on role
      this.routes = RoleAccess[user.role_alternate_name] || [];

      // Mark active route
      const routeIndex = this.routes.findIndex((item) => {
        return item.path === this.router.url;
      });
      this.activeRoute = this.routes[routeIndex];

      this.pendingComponentInit = true;
    });
  }

  // ================================================================================
  // Events
  // ================================================================================

  async onCacheData() {
    // If connection is offline, abort.
    if (this.network.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      this.toastService.present(toastMessages.caching.error.offline);
      return;
    }
    // Cache data
    this.loadingService.show(toastMessages.caching.dataDownload);
    try {
      await this.dataDownloadService.downloadAllData();
      this.toastService.present(toastMessages.caching.success);
    } catch (e) {
      this.toastService.present(toastMessages.common.error);
    }
    this.loadingService.hide();
  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Έξοδος',
      message: 'Είστε σίγουροι πως θέλετε να αποσυνδεθείτε;',
      buttons: [
        {
          text: 'Όχι',
          role: 'cancel',
          cssClass: 'secondary',
          handler: async () => {
            await alert.onDidDismiss();
          }
        }, {
          text: 'Ναι',
          handler: async () => {
            this.authService.logout();

            await alert.onDidDismiss();
          }
        }
      ]
    });

    await alert.present();
  }
}
