import {CommonService} from '../shared/common/common.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import cacheKeys from '../../configs/cache-keys.config';
import {NetworkService} from '../shared/offline/network.service';
import {BehaviorSubject, Observable} from 'rxjs';
import Refrigerator from '../../models/refrigerator/Refrigerator.model';
import apiRoutes from '../../configs/api-routes.config';
import { cloneDeep as _cloneDeep } from 'lodash';
import {CachingService} from '../shared/offline/caching.service';

@Injectable({
  providedIn: 'root'
})

export class RefrigeratorsService {

  private CACHE_KEY = cacheKeys.refrigerators;

  private refrigerators: Refrigerator[] = [];
  private refrigeratorsSubject: BehaviorSubject<Refrigerator[]>;

  constructor(private commonService: CommonService,
              private network: NetworkService,
              private cachingService: CachingService,
              private httpClient: HttpClient) {
    this.initService();
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Fetches refrigerators from the server
   */
  public async fetchRefrigerators(): Promise<Refrigerator[]> {
    try {
      if (this.network.isOnline()) {
        this.refrigerators = await this.httpClient.get<Refrigerator[]>(apiRoutes.refrigerators).toPromise();
      } else {
        const result: Refrigerator[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.refrigerators = result ? result : [];
      }
      this.updateRefrigeratorsSubject();
      return Promise.resolve(this.getRefrigerators());
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Fetches a refrigerator by id
   */
  public async getRefrigerator(serialNumber: string): Promise<Refrigerator> {
    try {
      let refrigerator: Refrigerator = null;
      if (this.network.isOnline()) {
        refrigerator = await this.httpClient
          .get<Refrigerator>(`${apiRoutes.refrigerators}?first=true&filter=serial_number = '${serialNumber}'`)
          .toPromise();
      } else {
        refrigerator = await this.cachingService.getLocalDataByField(this.CACHE_KEY, 'serial_number', serialNumber);
      }
      return Promise.resolve(refrigerator);
    } catch (e) {
      return Promise.reject();
    }
  }

  /**
   * Returns refrigerators as an observable
   */
  public getRefrigeratorsAsObservable(): Observable<Refrigerator[]> {
    return this.refrigeratorsSubject.asObservable();
  }

  /**
   * Returns refrigerators
   */
  public getRefrigerators(): Refrigerator[] {
    return _cloneDeep(this.refrigerators);
  }

  public resetState(): void {
    this.refrigerators = [];
    this.updateRefrigeratorsSubject();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private initService() {
    this.refrigeratorsSubject = new BehaviorSubject<Refrigerator[]>([]);
  }

  private updateRefrigeratorsSubject(): void {
    this.refrigeratorsSubject.next(_cloneDeep(this.refrigerators));
  }
}
