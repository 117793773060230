import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {

  @Input() type = 'dots';
  @Input() position = '';
  @Input() height = '';
  @Input() scale = '1';
  @Input() color = '';
  @Input() message = '';

  constructor() { }

  ngOnInit() {}

}
