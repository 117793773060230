import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import cacheKeys from '../../configs/cache-keys.config';
import RefrigeratorReturnRequest, {RefrigeratorReturnRequestDetail} from '../../models/refrigerator/return/RefrigeratorReturnRequest.model';
import {NetworkService} from '../shared/offline/network.service';
import {CachingService} from '../shared/offline/caching.service';
import {OfflineManagerService} from '../shared/offline/offline-manager.service';
import { cloneDeep as _cloneDeep, find as _find, isEqual as _isEqual } from 'lodash';
import apiRoutes from '../../configs/api-routes.config';
import * as date from '../../helpers/date/date.helper';

@Injectable({
  providedIn: 'root'
})
export class RefrigeratorReturnRequestsService {

  private CACHE_KEY = cacheKeys.refrigeratorReturnRequests;

  private refrigeratorReturnRequests: RefrigeratorReturnRequest[] = [];
  private refrigeratorReturnRequestsSubject: BehaviorSubject<RefrigeratorReturnRequest[]>;

  constructor(private httpClient: HttpClient,
              private network: NetworkService,
              private cachingService: CachingService,
              private offlineManagerService: OfflineManagerService) {
    this.initService();
  }

  // ================================================================================
  // Events
  // ================================================================================

  /**
   * Fetches refrigerator return requests from the server
   */
  public async fetchRequests(): Promise<RefrigeratorReturnRequest[]> {
    try {
      if (this.network.isOnline()) {
        this.refrigeratorReturnRequests =
          await this.httpClient.get<RefrigeratorReturnRequest[]>(apiRoutes.refrigeratorReturnRequests).toPromise();
        await this.cachingService.setLocalData(this.CACHE_KEY, this.refrigeratorReturnRequests);
      } else {
        const result: RefrigeratorReturnRequest[] = await this.cachingService.getLocalData(this.CACHE_KEY);
        this.refrigeratorReturnRequests = result ? result : [];
      }
      this.updateRefrigeratorReturnRequestsSubject();
      return Promise.resolve(this.getRequests());
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Fetches a refrigerator return request by id
   */
  public async fetchRequestById(id: number): Promise<RefrigeratorReturnRequest> {
    try {
      let request: RefrigeratorReturnRequest = null;
      if (this.network.isOnline()) {
        request =
          await this.httpClient.get<RefrigeratorReturnRequest>(`${apiRoutes.refrigeratorReturnRequests}/${id}`).toPromise();
        this.replaceRefrigeratorReturnRequest(request);
        this.updateRefrigeratorReturnRequestsSubject();
      } else {
        request = await this.cachingService.getLocalDataById(this.CACHE_KEY, id);
      }
      return Promise.resolve(request);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * POST a refrigerator return request
   */
  public async createRequest(request: RefrigeratorReturnRequest): Promise<RefrigeratorReturnRequest> {
    try {
      this.prepareRequestToBeCreated(request);
      let createdRequest: RefrigeratorReturnRequest = null;
      if (this.network.isOnline()) {
        createdRequest =
          await this.httpClient.post<RefrigeratorReturnRequest>(apiRoutes.refrigeratorReturnRequests, request).toPromise();
      } else {
        await this.offlineManagerService.storeRequest(apiRoutes.refrigeratorReturnRequests, 'POST', request);
        createdRequest = request;
      }
      this.addRefrigeratorReturnRequest(createdRequest);
      this.updateRefrigeratorReturnRequestsSubject();
      return Promise.resolve(createdRequest);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  /**
   * PUT a refrigerator return request
   */
  public async updateRequest(request: RefrigeratorReturnRequest): Promise<RefrigeratorReturnRequest> {
    try {
      const serverRequest = await this.fetchRequestById(request.id);
      request = this.filterOnlyUpdatedRefrigerators(request, serverRequest);
      let updatedRequest: RefrigeratorReturnRequest = null;
      if (this.network.isOnline()) {
        updatedRequest = await this.httpClient.put<RefrigeratorReturnRequest>(apiRoutes.refrigeratorReturnRequests, request).toPromise();
      } else {
        await this.offlineManagerService.storeRequest(apiRoutes.refrigeratorReturnRequests, 'PUT', request);
        updatedRequest = request;
      }
      this.replaceRefrigeratorReturnRequest(updatedRequest);
      this.updateRefrigeratorReturnRequestsSubject();
      return Promise.resolve(updatedRequest);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  /**
   * Returns refrigerator return requests
   */
  public getRequests(): RefrigeratorReturnRequest[] {
    return _cloneDeep(this.refrigeratorReturnRequests);
  }

  /**
   * Returns refrigerator return request by id
   */
  public getRequestById(id: number): RefrigeratorReturnRequest {
    return _cloneDeep(_find(this.refrigeratorReturnRequests, ['id', id]));
  }

  /**
   * Returns refrigerator return requests as an observable
   */
  public observeRequests(): Observable<RefrigeratorReturnRequest[]> {
    return this.refrigeratorReturnRequestsSubject.asObservable();
  }

  public resetState(): void {
    this.refrigeratorReturnRequests = [];
    this.updateRefrigeratorReturnRequestsSubject();
  }

  // ================================================================================
  // Helpers
  // ================================================================================

  private filterOnlyUpdatedRefrigerators(updatedRequest: RefrigeratorReturnRequest,
                                         serverRequest: RefrigeratorReturnRequest): RefrigeratorReturnRequest {
    const filteredRequest: RefrigeratorReturnRequest = _cloneDeep(updatedRequest);
    filteredRequest.details = filteredRequest.details.filter((entry: RefrigeratorReturnRequestDetail, index: number) => {
      return !_isEqual(entry, serverRequest.details[index]);
    });
    return filteredRequest;
  }

  private async addRefrigeratorReturnRequest(request: RefrigeratorReturnRequest): Promise<void> {
    if (request) {
      this.refrigeratorReturnRequests.push(request);
      await this.cachingService.pushLocalData(this.CACHE_KEY, request);
    }
  }

  private async replaceRefrigeratorReturnRequest(request: RefrigeratorReturnRequest): Promise<void> {
    if (request) {
      this.refrigeratorReturnRequests = this.refrigeratorReturnRequests.map(entry => {
        return entry.id === request.id ? request : entry;
      });
      await this.cachingService.replaceLocalData(this.CACHE_KEY, request);
    }
  }

  private prepareRequestToBeCreated(request: RefrigeratorReturnRequest): void {
    request.date_created = date.currentISOStringDate();
  }

  private updateRefrigeratorReturnRequestsSubject(): void {
    this.refrigeratorReturnRequestsSubject.next(this.getRequests());
  }

  private initService(): void {
    this.refrigeratorReturnRequestsSubject = new BehaviorSubject<RefrigeratorReturnRequest[]>([]);
  }
}
