const cacheKeys = {
  storedRequests: 'kk_stored_requests',
  salePoints: 'kk_sale_points',
  refrigerators: 'kk_refrigerators',
  refrigeratorOrders: 'kk_refrigerator_orders',
  refrigeratorMaterials: 'kk_refrigerator_materials',
  refrigeratorReturnRequests: 'kk_refrigerator_return_requests',
  inventories: 'kk_inventories',
  loadRequests: 'kk_load_requests',
  unloadRequests: 'kk_unload_requests'
};

export default cacheKeys;
