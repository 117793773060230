import {CommonService} from '../../../shared/services/shared/common/common.service';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  templateUrl: './refrigerator-modal.component.html',
  styleUrls: ['./refrigerator-modal.component.scss'],
})

export class RefrigeratorModalComponent implements OnInit {
  @Input() model: string;
  @Input() refrigerator: any;

  public sections: any[];

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
    let refrigeratorData = {};

    if (this.model === 'inventory_refrigerator') {
      refrigeratorData = {
        properties: [
          {
            name: 'Σειριακός Αριθμός',
            value: this.commonService.setValue(this.refrigerator.refrigerator_serial_number)
          },
          {
            name: 'Κωδικός Είδους',
            value: this.commonService.setValue(this.refrigerator.refrigerator_material_id)
          },
          {
            name: 'Περιγραφή Είδους',
            value: this.commonService.setValue(this.refrigerator.refrigerator_material_name)
          },
          {
            name: 'Παρτίδα',
            value: this.commonService.setValue(this.refrigerator.refrigerator_batch)
          },
          {
            name: 'Κωδικός Εικαστικού',
            value: this.commonService.setValue(this.refrigerator.refrigerator_eikastiko_id)
          },
          {
            name: 'Περιγραφή Εικαστικού',
            value: this.commonService.setValue(this.refrigerator.refrigerator_eikastiko_name)
          },
          {
            name: 'Καθαρό Βάρος',
            value: this.commonService.setValue(this.refrigerator.refrigerator_weight_net)
          },
          {
            name: 'Κωδικός Μοντέλου',
            value: this.commonService.setValue(this.refrigerator.refrigerator_model_id)
          },
          {
            name: 'Περιγραφή Μοντέλου',
            value: this.commonService.setValue(this.refrigerator.refrigerator_model_name)
          },
          {
            name: 'Κωδικός Κατάστασης',
            value: this.commonService.setValue(this.refrigerator.refrigerator_quality_id)
          },
          {
            name: 'Περιγραφή Κατάστασης',
            value: this.commonService.setValue(this.refrigerator.refrigerator_quality_name)
          },
          {
            name: 'Κωδικός Κατασκευαστή',
            value: this.commonService.setValue(this.refrigerator.refrigerator_manufacturer_id)
          },
          {
            name: 'Περιγραφή Κατασκευαστή',
            value: this.commonService.setValue(this.refrigerator.refrigerator_manufacturer_name)
          },
          {
            name: 'Κωδικός SAP',
            value: this.commonService.setValue(this.refrigerator.refrigerator_equipment_id)
          }
        ],
        title: 'Στοιχεία Ψυγείου'
      };
    } else {
      refrigeratorData = {
        properties: [
          {
            name: 'Σειριακός Αριθμός',
            value: this.commonService.setValue(this.refrigerator.serial_number)
          },
          {
            name: 'Κωδικός Είδους',
            value: this.commonService.setValue(this.refrigerator.material_id)
          },
          {
            name: 'Περιγραφή Είδους',
            value: this.commonService.setValue(this.refrigerator.material_name)
          },
          {
            name: 'Παρτίδα',
            value: this.commonService.setValue(this.refrigerator.batch)
          },
          {
            name: 'Κωδικός Εικαστικού',
            value: this.commonService.setValue(this.refrigerator.eikastiko_id)
          },
          {
            name: 'Περιγραφή Εικαστικού',
            value: this.commonService.setValue(this.refrigerator.eikastiko_name)
          },
          {
            name: 'Καθαρό Βάρος',
            value: this.commonService.setValue(this.refrigerator.weight_net)
          },
          {
            name: 'Κωδικός Μοντέλου',
            value: this.commonService.setValue(this.refrigerator.model_id)
          },
          {
            name: 'Περιγραφή Μοντέλου',
            value: this.commonService.setValue(this.refrigerator.model_name)
          },
          {
            name: 'Κωδικός Κατάστασης',
            value: this.commonService.setValue(this.refrigerator.quality_id)
          },
          {
            name: 'Περιγραφή Κατάστασης',
            value: this.commonService.setValue(this.refrigerator.quality_name)
          },
          {
            name: 'Κωδικός Κατασκευαστή',
            value: this.commonService.setValue(this.refrigerator.manufacturer_id)
          },
          {
            name: 'Περιγραφή Κατασκευαστή',
            value: this.commonService.setValue(this.refrigerator.manufacturer_name)
          },
          {
            name: 'Κωδικός SAP',
            value: this.commonService.setValue(this.refrigerator.equipment_id)
          }
        ],
        title: 'Στοιχεία Ψυγείου'
      };
    }

    this.sections = [refrigeratorData];
  }
}
