import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private location: Location) {
  }

  public goBack(): void {
    this.location.back();
  }

  public goForward(): void {
    this.location.forward();
  }

  public getPath(includeHash: boolean = false): string {
    return this.location.path(includeHash);
  }
}
