import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import apiRoutes from '../../configs/api-routes.config';
import { IUSer } from '../../models/user/User.model';
import {StateManagerService} from '../shared/common/state-manager.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public user: IUSer;

  constructor(private httpClient: HttpClient,
              private stateManagerService: StateManagerService,
              private router: Router) {
  }

  // ================================================================================
  // Events
  // ================================================================================

  public getUser(): Promise<any> {
    return new Promise((resolve) => {
      if (this.user) {
        return resolve(this.user);
      }

      this.httpClient.get(apiRoutes.user.self).subscribe((user) => {
        this.user = user;

        resolve(user);
      }, () => {
        sessionStorage.removeItem('AuthValidation');

        this.router.navigate(['/login'], { replaceUrl: true });
      });
    });
  }

  public getUserRole(): string {
    return this.user ? this.user.role_alternate_name : null;
  }

  public getUserId(): number {
    return this.user ? this.user.id : -1;
  }

  public async logout(): Promise<void> {
    await this.stateManagerService.resetStateBasedOnUserRole(this.user.role_alternate_name);
    this.user = null;
    sessionStorage.removeItem('AuthValidation');
    this.router.navigate(['/login'], { replaceUrl: true });
  }
}
