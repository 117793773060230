import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('AuthValidation'))}`
      },
      url: `${environment.apiUrl}${req.url}`
    });

    return next.handle(req);
  }
}
